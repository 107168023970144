import React from "react";
import { getTranslation } from "util/localisation";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const NumberInput = ({
  field,
  form: { touched, errors, submitCount },
  ...props
}) => {
  const { labeltext, required } = props;
  const isInvalid =
    (touched[field.name] && errors[field.name]) ||
    (errors[field.name] && submitCount > 0);
  let { value } = field;
  if (value === undefined) {
    value = "";
  }
  const inputProps = {
    ...field,
    value,
  };
  return (
    <React.Fragment>
      <Label>
        {required && "*"}
        {getTranslation(labeltext)}
      </Label>
      <FormGroup>
        <Input
          invalid={isInvalid}
          {...inputProps}
          {...props}
          type="number"
          lang="hr-HR"
        />
        {isInvalid && <FormFeedback>{errors[field.name]}</FormFeedback>}
      </FormGroup>
    </React.Fragment>
  );
};

export default NumberInput;
