import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from "@coreui/react";
import { hideErrorAlert } from "actionCreators/spinnerActionCreator";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import CustomSpinner from "components/Spinner/CustomSpinner";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Alert, Container } from "reactstrap";
import navs from "util/navs";
import {
  MEDITERAN_SECURITY_ROLES,
  SC_ROLES,
  ROLE_WEB_ADMIN,
  ROLE_SECURITY_ADMIN,
  ROLE_SECURITY_TASKS,
  ROLE_SECURITY_ORDERS,
  ROLE_SECURITY_SPECIFICATIONS,
  ROLE_SECURITY_CLIENT_SPECIFICATIONS,
  ROLE_SECURITY_CLIENT_ORDERS,
  ROLE_SECURITY_CLIENT_ORDERS_VIEW,
  ROLE_SECURITY_CLIENT_ORDERS_VIEW_CC,
  ROLE_SECURITY_CLIENT_ORDERS_MONITOR,
  ROLE_SECURITY_CLIENT_ORDERS_PROCESS,
  ROLE_SECURITY_COMPANY_LOGIN,
} from "util/roles/roleList";
import { checkUser } from "../../actionCreators/userActionCreators";
import { getTranslation } from "../../util/localisation";
import Footer from "./Footer";
import Header from "./Header";
import routes from "./routes";

const getStartingScreenPath = (roles) => {
  let path = "",
    msSpecificationRole = false,
    msTaskRole = false,
    msOrderRole = false,
    msClientOrderRole = false;
  roles.forEach((role) => {
    if (
      role.authority === ROLE_SECURITY_ADMIN ||
      role.authority === ROLE_SECURITY_TASKS
    ) {
      msTaskRole = true;
    }
    if (role.authority === ROLE_SECURITY_ORDERS) {
      msTaskRole = true;
    }
    if (
      role.authority === ROLE_SECURITY_SPECIFICATIONS ||
      role.authority === ROLE_SECURITY_CLIENT_SPECIFICATIONS
    ) {
      msSpecificationRole = true;
    }
    if (
      role.authority === ROLE_SECURITY_CLIENT_ORDERS ||
      role.authority === ROLE_SECURITY_CLIENT_ORDERS_VIEW ||
      role.authority === ROLE_SECURITY_CLIENT_ORDERS_VIEW_CC ||
      role.authority === ROLE_SECURITY_CLIENT_ORDERS_MONITOR ||
      role.authority === ROLE_SECURITY_CLIENT_ORDERS_PROCESS
    ) {
      msClientOrderRole = true;
    }
  });
  if (msTaskRole) {
    path = "app/task";
  } else if (msOrderRole) {
    path = "app/order";
  } else if (msSpecificationRole) {
    path = "app/specification";
  } else if (msClientOrderRole) {
    path = "app/clientOrder";
  }
  return path;
};

const canAccessApp = (roles) => {
  let canAccess = false;
  const APP_ROLES = [
    ...Object.keys(SC_ROLES),
    ...Object.keys(MEDITERAN_SECURITY_ROLES),
    ROLE_WEB_ADMIN,
  ];
  roles.forEach((role) => {
    if (APP_ROLES.includes(role.authority)) {
      canAccess = true;
    }
  });
  return canAccess;
};

class Layout extends Component {
  componentDidMount() {
    const { checkUser, userInfo } = this.props;
    checkUser(navs);
  }

  hideErrorAlert = () => {
    const { hideErrorAlert } = this.props;
    hideErrorAlert();
  };

  showMenu = (navs) => {
    let show = false;
    // for (let i = 0; i < navs.length; i++) {
    //   if (navs[i].children && navs[i].children.length != 0) {
    //     return true;
    //   }
    // }
    return true;
  };

  render() {
    const { userInfo, error, loading, filteredNavs } = this.props;

    if (loading) {
      return (
        <div style={{ height: "100vh" }}>
          <CustomSpinner />;
        </div>
      );
    }
    if (userInfo.failLogin) {
      console.log("no login");
      return <Redirect to="/login" />;
    }

    let roles = [];
    if (userInfo && userInfo.companyEmployer && userInfo.companyEmployer.user) {
      roles = userInfo.companyEmployer.user.authorities;
    }

    if (!canAccessApp(roles)) {
      return (
        <Alert color="warning">
          {getTranslation("app.noAccessRights.message")}
        </Alert>
      );
    }

    return (
      <div className="app">
        <AppHeader fixed>
          <Header />
        </AppHeader>
        <div className="app-body">
          {this.showMenu(filteredNavs.items) && (
            <AppSidebar fixed display="lg">
              <AppSidebarHeader />
              <AppSidebarForm />
              <AppSidebarNav navConfig={filteredNavs} {...this.props} />
              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>
          )}
          <main className="main">
            <Container fluid>
              <Alert
                color="warning"
                isOpen={error}
                toggle={this.hideErrorAlert}
              >
                {error}
              </Alert>
              <ErrorBoundary>
                <Switch>
                  {routes.map((route) => {
                    return (
                      route.component && (
                        <Route
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          render={(props) => {
                            return (
                              <route.component {...props} {...route.props} />
                            );
                          }}
                        />
                      )
                    );
                  })}

                  <Redirect from="/" to={getStartingScreenPath(roles)} />
                </Switch>
              </ErrorBoundary>
            </Container>
          </main>
        </div>
        <AppFooter>
          <Footer />
        </AppFooter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userState,
  loading: state.spinner.mainLoading,
  error: state.spinner.error,
  filteredNavs: state.userReducer.userState.filteredNavs,
});

const mapDispatchToProps = (dispatch) => ({
  checkUser: (navigationObject) => dispatch(checkUser(navigationObject)),
  hideErrorAlert: () => dispatch(hideErrorAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
