import CustomSpinner from "components/Spinner/CustomSpinner";
import CustomTable from "components/Table/CustomTable";
import React, { Component } from "react";
import { Button } from "reactstrap";
import { getFilterURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";

const TABLE_CODE = "company.login.list";

class CompanyLoginList extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    companyLoginList: { data: [], count: 0 },
    loading: true,
  };

  componentDidMount() {
    this.getCompanyLoginList();
  }

  getCompanyLoginList() {
    this.setState({ loading: true });
    const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
    const filter = getFilterURL(
      "company/securityGuardLogins",
      "",
      tableInfo.maxItems,
      tableInfo.currentPage * tableInfo.maxItems
    );

    fetch(`api/${filter}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            localStorage.setItem(
              TABLE_CODE,
              JSON.stringify({
                ...tableInfo,
              })
            );
            this.setState({ companyLoginList: data, loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.error("Error while getting company logins: ", error);
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, companyLoginList } = this.state;

    const map = {
      id: { field: "id", header: "tables.id", properties: {} },
      company: {
        field: "company.name",
        header: "company.login.table.header.company",
        properties: {},
      },
      user: {
        field: "user.fullName",
        header: "company.login.table.header.user",
        properties: {},
      },
      loginDate: {
        field: "loginDate",
        header: "company.login.table.header.loginDate",
        properties: { type: "date" },
      },
      latitude: {
        field: "latitude",
        header: "company.login.table.header.latitude",
        properties: {},
      },
      longitude: {
        field: "longitude",
        header: "company.login.table.header.longitude",
        properties: {},
      },
      manual: {
        field: "manual",
        header: "company.login.table.header.manual",
        properties: { type: "boolean" },
      },
    };

    return (
      <React.Fragment>
        <CustomTable
          data={companyLoginList}
          tableMap={map}
          tableCode={TABLE_CODE}
          loading={loading}
          onTableChange={() => this.getCompanyLoginList()}
        />
      </React.Fragment>
    );
  }
}

export default CompanyLoginList;
